<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <div class="card-title pt-3 px-3 d-flex justify-content-between">
            <div class="breadcrumb-left">
              <h4>Notice</h4>
              <div class="breadcrumb-sub-header">
                <router-link to="/dashboard">Dashboard </router-link>\ Notice
              </div>
            </div>
            <div class="breadcrumb-right">
              <div class="card-toolbar">
                <v-btn class="btn btn-primary" @click="createNotice()" dark>
                  Add Notice
                </v-btn>
              </div>
            </div>
          </div>
        </div>

        <div class="card-body">
          <div
            class="search-wrapper border-outline mb-5 px-3 py-3 d-sm-flex justify-content-start"
          >
            <v-row>
              <v-col cols="12" md="3">
                <v-text-field
                  label="Title"
                  v-model="search.title"
                  v-on:keyup.enter="searchNotice"
                  @input="search.title = $event !== null ? $event : ''"
                  clearable
                  outlined
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <v-select
                  :items="status"
                  v-model="search.is_active"
                  v-on:keyup.enter="searchNotice"
                  @input="search.is_active = $event !== null ? $event : ''"
                  label="Status"
                  item-text="name"
                  item-value="value"
                  outlined
                  dense
                ></v-select>
              </v-col>

              <v-col cols="12" md="6">
                <button
                  class="w-15 btn btn-primary float-right"
                  @click="searchNotice"
                >
                  Search
                </button>
              </v-col>
            </v-row>
          </div>

          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr class="text-left">
                  <th class="px-3">
                    <strong>Title</strong>
                  </th>
                  <th class="px-3"><strong>Status</strong></th>
                  <th class="pr-3 text-center"><strong>Action</strong></th>
                </tr>
              </thead>

              <template v-if="notices.length > 0">
                <draggable
                  @change="sort"
                  @start="drag = true"
                  @end="drag = false"
                  tag="tbody"
                >
                  <tr v-for="(item, index) in notices" :key="index">
                    <td class="px-2">
                      <a
                        @click="editNotice(item)"
                        class="text-primary font-weight-bolder text-hover-primary mb-1 font-size-lg"
                      >
                        <span class="ml-3">{{ item.title }}</span>
                      </a>
                    </td>
                    <td class="px-2">
                      <span
                        class="badge text-lg"
                        v-bind:class="{
                          'badge-success': item.is_active,
                          'badge-danger': !item.is_active
                        }"
                      >
                        {{ item.is_active ? "Active" : "In Active" }}
                      </span>
                    </td>

                    <td class="pr-0 px-2 text-center">
                      <template>
                        <b-dropdown
                          size="sm"
                          variant="link"
                          toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                          no-caret
                          right
                          no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="editNotice(item)" class="navi-link">
                                <span class="navi-icon">
                                  <v-icon color="blue darken-2"
                                    >fas fa-edit</v-icon
                                  >
                                </span>
                                <span class="navi-text">Edit Notice</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a
                                @click.prevent="deleteNotice(item.id)"
                                class="navi-link"
                              >
                                <span class="navi-icon">
                                  <v-icon color="red">fas fa-trash</v-icon>
                                </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                </draggable>
              </template>
              <template v-else>
                <tr>
                  <td class="text-center" colspan="5">
                    <h5>No Data Found</h5>
                  </td>
                </tr>
              </template>
            </table>
          </div>
        </div>

        <create-and-update
          ref="create-and-update"
          @refresh="getAllNotices"
        ></create-and-update>
      </div>
    </div>
  </div>
</template>

<script>
import CreateAndUpdate from "@/view/pages/cms/notice/CreateAndUpdate";
import NoticeService from "@/service/cms/notice/NoticeService";
import draggable from "vuedraggable";

const noticeService = new NoticeService();
export default {
  name: "Index",
  components: {
    CreateAndUpdate,
    draggable
  },
  data() {
    return {
      drag: false,
      notices: [],
      page: null,
      search: {
        name: "",
        is_active: ""
      },
      status: [
        { name: "Active", value: 1 },
        { name: "Inactive", value: 0 }
      ]
    };
  },
  mounted() {
    this.getAllNotices();
  },

  methods: {
    searchNotice() {
      this.getAllNotices();
    },

    createNotice() {
      this.$refs["create-and-update"].createNotice();
    },

    editNotice(notice) {
      this.$refs["create-and-update"].editNotice(notice);
    },

    deleteNotice(id) {
      this.$confirm({
        message: `Are you Sure ??`,
        button: {
          yes: "Yes",
          no: "No"
        },
        callback: confirm => {
          if (confirm) {
            noticeService.delete(id).then(response => {
              if (response.data.status === "OK") {
                this.$snotify.success("Delete Successfully");
                this.getAllNotices();
              } else {
                this.$snotify.error("Something Went Wrong");
              }
            });
          }
        }
      });
    },

    getAllNotices() {
      noticeService
        .paginate(this.search, this.page)
        .then(response => {
          this.notices = response.data.data;
        })
        .finally(() => {
          return false;
        });
    },

    sort(notice) {
      noticeService.sort(notice).then(response => {
        if (response.data.status == "OK") {
          this.$snotify.success("Sorted Successfully");
          this.getAllNotices();
        } else {
          this.$snotify.error("Something Went Wrong");
        }
      });
    }
  }
};
</script>

<style scoped></style>
