<template>
  <v-row align="center">
    <v-dialog v-model="dialog" persistent max-width="800px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ edit ? "Edit" : "Add" }} Notice</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form lazy-validation autocomplete="off">
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="notice.name"
                    label="Name"
                    outlined
                    dense
                    required
                    hide-details
                  ></v-text-field>
                  <span class="text-danger" v-if="$v.notice.name.$error">
                    Title is Required !!
                  </span>
                  <span class="text-danger" v-if="errors.name">
                    {{ errors.name[0] }}
                  </span>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-menu
                    v-model="menuDate"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="notice.visible_from_date"
                        label="Visible From Date"
                        prepend-icon=""
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        dense
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      no-title
                      :min="date"
                      v-model="notice.visible_from_date"
                      @input="menuDate = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12">
                  Description
                  <ckeditor
                    v-model="notice.description"
                    :config="editorConfig"
                  ></ckeditor>
                  <span class="text-danger" v-if="$v.notice.description.$error">
                    Description is Required !!
                  </span>
                  <span class="text-danger" v-if="errors.description">
                    {{ errors.description[0] }}
                  </span>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-switch
                    v-model="notice.is_active"
                    label="Status"
                    outlined
                    dense
                    required
                  ></v-switch>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" dark @click="createOrUpdate">
            {{ edit ? "Update" : "Save" }}
          </v-btn>
          <v-btn color="red darken-1" dark @click="close"> Cancel </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import NoticeService from "@/service/cms/notice/NoticeService";

const noticeService = new NoticeService();
export default {
  name: "CreateAndUpdate",
  data() {
    return {
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dialog: false,
      menuDate: false,
      edit: false,
      notice: {
        name: "",
        description: "",
        position: "",
        visible_from_date: "",
        is_active: true
      },
      errors: [],
      editorConfig: {
        toolbar: [["Bold", "Italic", "Underline", "BulletedList"]]
      }
    };
  },
  validations: {
    notice: {
      name: { required },
      description: { required },
      visible_from_date: { required }
    }
  },
  methods: {
    close() {
      this.dialog = false;
      this.reset();
    },

    createNotice() {
      this.dialog = true;
      this.edit = false;
      this.reset();
    },

    editNotice(notice) {
      this.edit = true;
      this.reset();
      this.notice = notice;
    },

    createOrUpdate() {
      this.$v.notice.$touch();
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 3000);
      } else {
        if (!this.edit) {
          this.create();
        } else {
          this.update();
        }
      }
    },

    reset() {
      this.$v.$reset();
      this.notice = {
        name: "",
        description: "",
        position: "",
        visible_from_date: "",
        is_active: true
      };
    },

    create() {
      noticeService
        .store(this.notice)
        .then(response => {
          if (response.data.status === "OK") {
            this.$snotify.success("Add notice Successfully");
            this.close();
          } else {
            this.$snotify.error("Something Went Wrong");
          }
        })
        .catch(err => {
          if (err.response.status == 422) {
            this.errors = err.response.data.errors;
          }
          setTimeout(() => {
            this.errors = [];
          }, 3000);
        });
    },

    update() {
      noticeService.update(this.notice.id, this.notice).then(response => {
        if (response.data.status === "OK") {
          this.$snotify.success("Update notice Successfully");
          this.close();
        } else {
          this.$snotify.error("Something Went Wrong");
        }
      });
    }
  }
};
</script>

<style scoped></style>
